<template>
  <CForm>
    <loader :loading="requisicaoAberta"></loader>
    <CRow>
      <CCol md="2">
        <CInput
          label="Prefixo"
          :value.sync="$v.projeto.prefixo.$model"
          :isValid="verificarCampo('prefixo')"
          placeholder="Prefixo"
          invalidFeedback="O prefixo deve ser númerico"
        />
      </CCol>
      <CCol md="10">
        <CInput
          label="Projeto"
          :value.sync="$v.projeto.projeto.$model"
          :isValid="verificarCampo('projeto')"
          placeholder="Titulo do projeto"
          invalidFeedback="É preciso informar o nome do projeto"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <CInput
          label="Cliente"
          :value.sync="$v.projeto.cliente.$model"
          :isValid="verificarCampo('cliente')"
          placeholder="Nome do Cliente"
          invalidFeedback="É preciso informar o nome do cliente"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="4">
        <CInput label="CEP" v-model.lazy="projeto.cep" placeholder="CEP" @change="consultarCep" />
      </CCol>
      <CCol md="8">
        <CInput label="Endereço" :value.sync="projeto.endereco" placeholder="Endereço" />
      </CCol>
      <CCol md="6">
        <CInput label="Complemento" :value.sync="projeto.complemento" placeholder="Complemento" />
      </CCol>
      <CCol md="6">
        <CInput label="Bairro" :value.sync="projeto.bairro" placeholder="Bairro" />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="4">
        <CInput label="Cidade" :value.sync="projeto.cidade" placeholder="Cidade" />
      </CCol>
      <CCol md="3">
        <CInput label="Estado" :value.sync="projeto.estado" placeholder="Estado" />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <CTextarea label="Observação" :value.sync="projeto.observacao" />
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import Axios from "axios";
import Loader from "./../Componentes/loader";
import cep from "cep-promise";

let obtenhaObjetoProjeto = () => {
  return {
    prefixo: "",
    projeto: "",
    cliente: "",
    cep: "",
    endereco: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    observacao: ""
  };
};
export default {
  name: "SBFormularioDados",
  components: {
    Loader
  },
  props: {
    projeto: {
      type: Object,
      default: obtenhaObjetoProjeto
    },
    enviar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requisicaoAberta: false
    };
  },
  validations: {
    projeto: {
      prefixo: { required, numeric },
      projeto: { required },
      cliente: { required }
    }
  },
  methods: {
    verificarCampo(nomeDoCampo) {
      const campo = this.$v.projeto[nomeDoCampo];
      if (!campo.$dirty) {
        return null;
      }
      return !(campo.$invalid || campo.$model === "");
    },
    consultarCep() {
      this.requisicaoAberta = true;
      cep(this.projeto.cep)
        .then(response => {
          this.projeto.endereco = response.street;
          this.projeto.cidade = response.city;
          this.projeto.estado = response.state;
          this.projeto.bairro = response.neighborhood;
        })
        .catch(erro => {
          this.$eventoHub.$emit("adicionarMensagemAviso", {
            mensagem: erro.message,
            classe: "bg-danger"
          });
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    salvarProjeto() {
      this.$v.projeto.$touch();
      if (this.$v.projeto.$invalid) {
        return;
      }
      this.requisicaoAberta = true;
      let url =
        this.projeto.id !== undefined
          ? "/projeto/" + this.projeto.id
          : "/projeto/";
      Axios.post(url, this.projeto)
        .then(response => {
          this.$emit("projetoSalvo", response.data.retorno);
        })
        .catch(error => {
          if (error.response.status === 406) {
            this.$swal(
              "Erro",
              this.gerarMenssagemDeErro(error.response.data.retorno.auxilio)
            );
          } else if (error.response.status === 422) {
            this.$swal("Erro", error.response.data.retorno[0]);
          }
          this.$swal(
            "Erro",
            error.response.data.retorno["mensagem"] +
              ": " +
              error.response.data.retorno["erro"]
          );
          this.$emit("projetoErro");
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    gerarMenssagemDeErro(erros) {
      if (typeof erros !== "object")
        return '<div class="text-left">' + erros + "</div>";
      let html = '<div class="text-left">';
      Object.keys(erros).forEach(Campo => {
        html +=
          '<div class="alert alert-danger" role="alert">' +
          '<h5 class="alert-heading">' +
          Campo +
          "</h5>" +
          '<ul class="list-group list-group-flush">';
        erros[Campo].forEach(ErroCampo => {
          html += '<li class="list-group-item">' + ErroCampo + "</li>";
        });
        html += "</ul></div>";
      });
      html += "</div>";

      return html;
    }
  },
  created() {
    this.$eventoHub.$on("salvarProjeto", this.salvarProjeto);
  },
  destroyed() {
    this.$eventoHub.$off("salvarProjeto", this.salvarProjeto);
  }
};
</script>

<style>
</style>
